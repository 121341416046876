<template>
  <div>
    <h1>{{ name }}</h1>
    <hr>

    <b-card class="mt-2" no-body v-bind:key="meal.uuid" v-for="meal in meals">
      <b-card-body>
        <b-card-title>
          {{ meal.name }}
          <b-button variant="outline-success" class="float-right" v-b-toggle="'collapse-' + meal.uuid">
            <b-icon-plus></b-icon-plus>
          </b-button>
        </b-card-title>
        <b-card-sub-title>
          {{ meal.description }}
        </b-card-sub-title>
        <b-card-text class="text-muted">{{ meal.basePrices[meal.selected_size] | toCurrency }}</b-card-text>

        <b-collapse v-bind:id="'collapse-' + meal.uuid" accordion="meal-collapses" class="mt-2">

          <b-form-select class="mb-3" v-model="meal.selected_size" v-if="Object.keys(sizesForMeal(meal)).length > 1">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>Größe wählen</b-form-select-option>
            </template>

            <b-form-select-option v-for="(size, uuid) in sizesForMeal(meal)" v-bind:value="uuid" v-bind:key="uuid">
              {{ size }}
            </b-form-select-option>
          </b-form-select>


          <template v-if="meal.mix">
              <div v-for="idx in Array.from(Array(meal.mixCount).keys())" v-bind:key="idx">
                  <b-form-select v-model="meal.mix_choices[idx].meal" @change="updateMixMealIngredients(meal, idx)">
                      <template v-slot:first>
                          <b-form-select-option :value="null" disabled>Mix Gericht {{ idx + 1}}</b-form-select-option>
                      </template>

                      <b-form-select-option v-for="uuid in meal.mixMeals" v-bind:value="uuid" v-bind:key="uuid">
                          {{ mealForUuid(uuid).name }}
                      </b-form-select-option>
                  </b-form-select>


                  <b-form-checkbox-group switches v-if="meal.mix_choices[idx].meal != null" v-model="meal.mix_choices[idx].selected_ingredients" style="column-count: 2;" stacked>
                      <b-form-checkbox v-for="uuid in sortByNameKey(mealForUuid(meal.mix_choices[idx].meal).ingredients)" v-bind:key="uuid" :value="uuid">
                          {{ ingredientExtras[uuid].name }}
                      </b-form-checkbox>

                  </b-form-checkbox-group>

                  <b-button class="btn-block" v-if="meal.mix_choices[idx].meal != null && mealForUuid(meal.mix_choices[idx].meal).maxExtras > 0" v-b-toggle="'collapse-mix-extras-' + idx">Extras</b-button>
                  <b-collapse v-if="meal.mix_choices[idx].meal != null" v-bind:id="'collapse-mix-extras-' + idx">
                      <b-form-valid-feedback :state="validateExtras(meal)">Sie können maximal {{ mealForUuid(meal.mix_choices[idx].meal).maxExtras }} Extras wählen
                      </b-form-valid-feedback>
                      <b-form-invalid-feedback :state="validateExtras(meal)">Sie können maximal {{ mealForUuid(meal.mix_choices[idx].meal).maxExtras }} Extras wählen
                      </b-form-invalid-feedback>
                      <b-form-checkbox-group class="mb-1"
                                             :state="validateExtras(meal)"
                                             v-model="meal.mix_choices[idx].selected_extras"
                                             size="xm"
                                             text-field="name"
                                             value-field="uuid"
                                             switches
                                             style="column-count: 2;"
                                             stacked
                      >
                          <b-form-checkbox v-for="uuid in sortByNameKey(mealForUuid(meal.mix_choices[idx].meal).extras)" v-bind:key="uuid" :value="uuid">
                              {{ ingredientExtras[uuid].name }}
                          </b-form-checkbox>
                      </b-form-checkbox-group>

                  </b-collapse>
                  <hr>
              </div>
          </template>


          <b-form-select class="mb-3" v-model="meal.selected_options[option]" v-bind:key="option"
                         v-for="option in meal.options">
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>{{ options[option].name }}</b-form-select-option>
            </template>

              <!-- TODO: add validation / red -->
            <b-form-select-option v-for="value in options[option].values" v-bind:value="value" v-bind:key="value">
              {{ optionValues[value].name }}
              <template v-if="optionValues[value].prices[meal.selected_size] > 0">(+ {{ optionValues[value].prices[meal.selected_size] | toCurrency }})</template>
            </b-form-select-option>
          </b-form-select>

          <b-form-checkbox-group class="mb-1"
                                 v-model="meal.selected_ingredients" switches style="column-count: 2;" stacked>
            <b-form-checkbox v-for="uuid in sortByNameKey(meal.ingredients)" v-bind:key="uuid" :value="uuid">
              {{ ingredientExtras[uuid].name }}
            </b-form-checkbox>

          </b-form-checkbox-group>
          <br/>

          <template v-if="meal.extras.length > 0">
            <b-button v-b-toggle="'collapse-extras-' + meal.uuid" class="mb-3">Extras</b-button>
            <b-collapse v-bind:id="'collapse-extras-' + meal.uuid">
              <b-form-valid-feedback :state="validateExtras(meal)">Sie können maximal {{ meal.maxExtras }} Extras wählen
              </b-form-valid-feedback>
              <b-form-invalid-feedback :state="validateExtras(meal)">Sie können maximal {{ meal.maxExtras }} Extras wählen
              </b-form-invalid-feedback>
              <b-form-checkbox-group class="mb-1"
                                     :state="validateExtras(meal)"
                                     v-model="meal.selected_extras"
                                     size="xm"
                                     text-field="name"
                                     value-field="uuid"
                                     switches
                                     style="column-count: 2;"
                                     stacked
              >
                <b-form-checkbox v-for="uuid in sortByNameKey(meal.extras)" v-bind:key="uuid" :value="uuid">
                  {{ ingredientExtras[uuid].name }}
                  <template v-if="ingredientExtras[uuid].prices[meal.selected_size] > 0">(+ {{ ingredientExtras[uuid].prices[meal.selected_size] | toCurrency }})</template>
                </b-form-checkbox>

              </b-form-checkbox-group>
            </b-collapse>
          </template>

          <b-form class="mb-3">
            <b-form-group label="Kommentar"  v-bind:description="`(${meal.comment != null ? meal.comment.length : 0}/255) Kommentar`">
              <b-textarea v-model="meal.comment" maxlength="255"></b-textarea>
            </b-form-group>
          </b-form>

          <br/>

          <b-form-spinbutton v-model="meal.amount" value="1" inline min="1" max="10" style="width: 10rem"
                             class="text-center"></b-form-spinbutton>

          <b-button class="float-right" variant="outline-success"
                    v-on:click="addToCart(meal);$root.$emit('bv::toggle::collapse', 'collapse-' + meal.uuid)"
                    v-bind:disabled="Object.values(meal.selected_options).filter(x => x == null).length > 0 || !validateExtras(meal) || !isOpenDeliveryPickUp(new Date()) || !validateMix(meal)">
              Hinzufügen ({{ calculatePrice(meal) | toCurrency }})
          </b-button>
        </b-collapse>
      </b-card-body>
    </b-card>
  </div>
</template>

<style>

</style>

<script>
async function update(self, categoryId, done) {
  self.$loading = true;
  let response = await self.$http.get("/api/category/" + categoryId);
  self.name = response.data.name;
  self.sizes = response.data.sizes;

  for (const option of response.data.options) {
    self.options = Object.assign(self.options, {[option.uuid]: option});
  }
  for (const optionValue of response.data.optionValues) {
    self.optionValues = Object.assign(self.optionValues, {[optionValue.uuid]: optionValue});
  }

  let meals = response.data.meals;

  self.setTitle(self.name);


  for (const meal of meals) {
    Object.assign(meal, {
      amount: 1,
      comment: '',
      selected_size: Object.keys(meal.basePrices)[0],
      selected_extras: [],
      selected_options: Object.assign({}, ...meal.options.map(option => ({[option]: null}))),
      selected_ingredients: meal.ingredients,
      mix_choices: Array.from({length: meal.mixCount}, () => { return {
      meal: null,
      selected_extras: [],
      selected_ingredients: []
      }})
    });
  }

  self.meals = meals.sort((m1, m2) => m1.no.localeCompare(m2.no,  undefined, {numeric: true, sensitivity: 'base'}));
  await done();
  self.$loading = false;
}

export default {
  async beforeCreate() {
    self.$loading = true;
    let response = await this.$http.get('/api/ingredient/list');
    this.ingredientExtras = response.data.reduce((all, ingredientExtra) => ({
      ...all,
      [ingredientExtra.uuid]: ingredientExtra
    }), {});


    let catId = this.$route.params.id;
    await update(this, catId, () => {});
  },
  beforeRouteUpdate(to, from, next) {
    let catId = to.params.id;
    update(this, catId, next);
  },

  data() {
    return {
      ingredientExtras: [],
      sizes: [],
      options: [],
      optionValues: [],
      name: this.name,
      meals: this.meals
    };

  },
  methods: {
    addToCart(meal) {
        // TODO open overview
      let cartItem = {
        amount: meal.amount,
        title: meal.name,
        price: this.calculatePrice(meal),
        type: meal.type,
        options: [],

        configuration: {
          meal: meal.uuid,
          amount: meal.amount,
          comment: meal.comment,
          selectedSize: meal.selected_size,
          selectedExtras: meal.selected_extras,
          selectedIngredients: meal.selected_ingredients,
          selectedOptions: Object.assign({}, meal.selected_options),
          mixChoices: meal.mix_choices
        }
      };

      for (const [optionUuid, valueUuid] of Object.entries(meal.selected_options)) {
        let optionValue = this.optionValues[valueUuid];
        let optionItem = {
          optionId: optionUuid,
          valueId: optionValue.uuid,
          name: optionValue.name,
        };
        cartItem.options.push(optionItem);
      }

      this.$store.dispatch('add', {cartItem});


      Object.assign(meal, {
        amount: 1,
        comment: '',
        selected_size: Object.keys(meal.basePrices)[0],
        selected_extras: [],
        selected_options: Object.assign({}, ...meal.options.map(option => ({[option]: null}))),
        selected_ingredients: meal.ingredients,
        mix_choices: Array.from({length: meal.mixCount}, () => { return {
          meal: null,
          selected_extras: [],
          selected_ingredients: []
        }})
      });
    },
    updateMixMealIngredients(meal, no) {
        this.$loading = true;
        let mixMeal = this.mealForUuid(meal.mix_choices[no].meal);
        meal.mix_choices[no].selected_ingredients = mixMeal.ingredients;
        this.$loading = false;
    },
    validateMix(meal) {
        if (!meal.mix) return true;
        return meal.mix_choices.filter(entry => entry.meal === null).length === 0;
    },
    validateExtras(meal) {
      return meal.selected_extras.length <= meal.maxExtras;
    },
    calculatePrice(meal) {
      let mixPrice = 0;
      if (meal.mix) {
        for (const mixMealConfiguration of meal.mix_choices) {
          if (mixMealConfiguration.meal == null) continue;
            let mixMealPrice = this.mealForUuid(mixMealConfiguration.meal).basePrices[meal.selected_size];

            let optionsPrice = 0; /*Object.entries(mixMealConfiguration.selected_options)
              // eslint-disable-next-line no-unused-vars
              .filter(([_, value_uuid]) => value_uuid != null)
              .map(([, value_uuid]) => this.optionValues[value_uuid])
              .reduce((sum, current) => sum + (current.prices[meal.selected_size] ?? 0), 0);
              */

            let extrasPrice = mixMealConfiguration.selected_extras
              .map(extra_uuid => this.ingredientExtras[extra_uuid])
              .reduce((sum, current) => sum + (current.prices[meal.selected_size] ?? 0), 0);


            let ingredientsDeduction = Object.values(this.mealForUuid(mixMealConfiguration.meal).ingredients)
              .map(uuid => this.ingredientExtras[uuid])
              .filter(ingredient => !mixMealConfiguration.selected_ingredients.includes(ingredient.uuid))
              .reduce((sum, current) => sum + (current.deductions[meal.selected_size] ?? 0), 0);

            let extrasAndIngredients = extrasPrice - ingredientsDeduction;


            // If the mix meal has a cent price we want to remove it
            mixMealPrice = Math.floor(mixMealPrice / 100) * 100;
            mixPrice += Math.round(mixMealPrice / meal.mixCount) + optionsPrice + (extrasAndIngredients > 0 ? extrasAndIngredients : 0);
        }
      }

      let optionsPrice = Object.entries(meal.selected_options)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, value_uuid]) => value_uuid != null)
          .map(([, value_uuid]) => this.optionValues[value_uuid])
          .reduce((sum, current) => sum + (current.prices[meal.selected_size] ?? 0), 0);

      let extrasPrice = meal.selected_extras
          .map(extra_uuid => this.ingredientExtras[extra_uuid])
          .reduce((sum, current) => sum + (current.prices[meal.selected_size] ?? 0), 0);

      let ingredientsDeduction = Object.values(meal.ingredients)
          .map(uuid => this.ingredientExtras[uuid])
          .filter(ingredient => !meal.selected_ingredients.includes(ingredient.uuid))
          .reduce((sum, current) => sum + (current.deductions[meal.selected_size] ?? 0), 0);

      let extrasAndIngredients = extrasPrice - ingredientsDeduction;
      return meal.amount * (meal.basePrices[meal.selected_size] + optionsPrice + (extrasAndIngredients > 0 ? extrasAndIngredients : 0) + mixPrice);
    },
    sizesForMeal(meal) {
      return Object.filter(this.sizes, ([uuid, ]) => Object.keys(meal.basePrices).indexOf(uuid) !== -1);
    },
    mealForUuid(uuid) {
        return this.meals.find(meal => meal.uuid === uuid);
    },
    sortByNameKey(arr) {
      // Note: we need to slice() first since sort mutates the array and vue would re-render the component leading to an infinite loop
      return arr.slice().sort((a, b) => this.ingredientExtras[a].name.localeCompare(this.ingredientExtras[b].name));
    }
  }
}
</script>